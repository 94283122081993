@import '~antd/dist/antd.less';

html {
  @media screen and (min-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    font-size: 15px;
  }

  body {
    background: #ffffff;
    font-size: 1rem;

    @media (prefers-color-scheme: dark) {
      body {
        background: #ffffff;
      }
    }
  }
}
@primary-color: #367f98;@input-bg: #f6f8fd;@picker-bg: #f6f8fd;@select-background: #f6f8fd;@btn-border-radius-base: 0.5rem;@btn-border-radius-sm: 0.5rem;